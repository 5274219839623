import { animated, useSpring } from '@react-spring/web';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DraggableProvided, Droppable } from 'react-beautiful-dnd';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useTruncatedText from '../../../../../../../hooks/useTruncatedText';
import useWindowResize from '../../../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useUpdateTopicMutation } from '../../../../../../../redux/services/resourceApis/courses/topicsApi';
import { useCreateStepMutation } from '../../../../../../../redux/services/resourceApis/steps/stepsApi';
import { AddStepParams } from '../../../../../../../redux/services/resourceApis/steps/types';
import { MetaUserAccess } from '../../../../../../../types/Curriculum';
import { EditableCourseCurriculumElement } from '../../../../../../../types/CurriculumElement';
import { Emoji } from '../../../../../../../types/Emoji';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import Icon from '../../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { useFlashNotification } from '../../../../../../FlashNotificationContext';
import { fontSm4 } from '../../../../../../styled/TypeSystem';
import EmojiSelect from '../../../../../ContentLibrary/components/SubjectEmoji/EmojiSelect';
import { ReadingTimeProvider } from '../../../../../editor/shared/ReadTimeFlyout/ReadingTimeContext';
import ReadTimeFlyout from '../../../../../editor/shared/ReadTimeFlyout/ReadTimeFlyout';
import { routes } from '../../../../../publicApplication/applicationRouter';
import routeTo from '../../../../../publicApplication/routeTo';
import useActiveMenuHandler from '../../../../../publicApplication/utils/useActiveMenuHandler';
import ChevronControl from '../../../../../shared/ChevronControl/ChevronControl';
import StatusBadgeActions from '../../../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import EditableCurriculumElementTitle from '../../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import CurriculumElementThreeDot from '../../../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { PlaceholderBlock } from '../../../../../shared/DragAndDrop/styles';
import { Placeholder } from '../../../../../shared/DragAndDrop/types';
import { viewableUserAccess } from '../../../../../shared/helpers';
import {
  CurriculumElementThreeDotWrapper,
  EditBadgeWrapper,
  EmojiWrapper,
  GripVerticalIconWrapper,
  OutlineCurriculumElementRowContainer,
  OutlineRowContainer,
  StyledBadge,
} from '../../../../shared/CurriculumElementRowStyles';
import {
  ActionRowWrapper,
  RowActionsContainer,
  RowCardBody,
  RowContainer,
} from '../../../../shared/CurriculumRowStyles';
import StepsList from '../../StepsList/StepsList';
import OutlineActionRow from '../OutlineActionRow';

const SignatureLabel = styled.span`
  display: flex;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  font-style: italic;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  margin-inline: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};

  ${fontSm4};
`;

const ESignature = styled.div`
  white-space: nowrap;
`;

const ESignatureRowContainer = styled(RowContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme: { vars } }) => vars.borderSurface1};
  width: auto;
  margin: ${({ theme: { constants } }) =>
    `0 ${constants.spacerLg1} ${constants.spacerSm3}  ${constants.spacerMd3}`};
`;

const ESignatureRowContent = styled.div`
  display: flex;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm4};
`;

const ArrowIconWrapper = styled.div`
  cursor: pointer;
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerSm3} 0`};
`;

const StyledActionRowWrapper = styled(ActionRowWrapper)`
  margin: ${({ theme: { constants } }) =>
    `0 ${constants.spacerLg1} ${constants.spacerSm3}  ${constants.spacerMd3}`};
`;

const OutlineChildContainerRow = styled(animated.div)`
  margin-left: 5rem;
  overflow: 'hidden';
`;

export type OutlineCourseTableRowProps = {
  curriculumId: number;
  isLocked?: boolean;
  curriculumElement: EditableCourseCurriculumElement;
  signaturable: boolean;
  provided: DraggableProvided;
  placeholder: Placeholder;
  blockEditPrivileges: boolean;
  emoji?: Emoji;
  userAccess?: MetaUserAccess;
};

const t = initTranslations('curriculums_view');

const OutlineCourseTableRow = ({
  curriculumId,
  isLocked,
  curriculumElement,
  signaturable,
  provided,
  placeholder,
  blockEditPrivileges,
  emoji,
  userAccess,
}: OutlineCourseTableRowProps) => {
  const { slug } = useCurrentAccount();
  const [createStep] = useCreateStepMutation();
  const [updateCourse, updateCourseResult] = useUpdateTopicMutation();
  const { isMobile } = useWindowResize();
  const [isEditing, setIsEditing] = useState(false);
  const badgeRef = useRef<HTMLSpanElement>(null);
  const showTooltip = useTruncatedText(badgeRef, { maxLines: 1 });
  const [showSteps, setShowSteps] = useState(true);
  const {
    elementId: courseId,
    disableTranslation,
    eSignatureDisplayedAndRequired,
    canBeModified,
    id,
    element,
  } = curriculumElement;
  const { title, status, readingTime, autoGeneratedReadingTime } = element;
  const {
    step: { singular: stepTermSingular },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const primaryButtonAction = useCallback(
    (value: string) => {
      const params: AddStepParams = {
        courseId,
        title: value,
      };
      return createStep(params);
    },
    [courseId, createStep]
  );

  const [ref, bounds] = useMeasure();
  const containerStyle = useSpring({
    height: showSteps ? bounds.height + 8 : 0,
    config: { tension: 400, friction: 40 },
  });

  const { isMenuOpen } = useActiveMenuHandler({
    menuId: `curriculum-element-three-dot-${curriculumElement.id}`,
  });
  const { isSuccess, error } = updateCourseResult;

  const { flash } = useFlashNotification();

  useEffect(() => {
    if (error) {
      flash('error', t('update_title_error'));
    }
  }, [error, flash]);

  const courseUrl = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return routes.courseConsume({ slug, id: courseId }).href;
    } else {
      return routes.courseEditor({ slug, id: courseId }).href;
    }
  }, [courseId, curriculumId, slug, userAccess]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='curriculum-element-item'
      id={`curriculum-element-course-${courseId}`}
    >
      <OutlineCurriculumElementRowContainer className='outline-curriculum-element-container'>
        <GripVerticalIconWrapper remainVisible={isMenuOpen}>
          <Icon name='grip-vertical' weight='solid' />
        </GripVerticalIconWrapper>
        <OutlineRowContainer>
          <RowCardBody isLocked={isLocked || eSignatureDisplayedAndRequired || blockEditPrivileges}>
            <ChevronControl
              id={id}
              isOpen={showSteps}
              noAccess={false}
              onClick={() => setShowSteps((prev) => !prev)}
              title={title}
            />
            <EditBadgeWrapper hasChevron>
              {showTooltip && <Tooltip id={`topic-badge-${courseId}`} text={topicTermSingular} />}
              <div data-for={`topic-badge-${courseId}`} data-tip>
                <StyledBadge clickable forwardRef={badgeRef} text={topicTermSingular} type='info' />
              </div>
            </EditBadgeWrapper>
            <EmojiWrapper id={`course-${courseId}-row-emoji-picker`}>
              <EmojiSelect
                clearEmoji={() => {
                  updateCourse({ id: courseId, emoji: null });
                }}
                emoji={emoji}
                noEmojiIconName='file-lines'
                onEmojiSelect={(emoji: BaseEmoji) => {
                  updateCourse({ emoji: emoji.native, id: courseId });
                }}
                usePortal
              />
            </EmojiWrapper>
            <EditableCurriculumElementTitle
              disableTranslation={disableTranslation}
              editable={canBeModified && !blockEditPrivileges}
              id={courseId}
              isEditing={isEditing}
              isLocked={isLocked}
              isSuccess={isSuccess}
              route={courseUrl}
              setIsEditing={setIsEditing}
              title={title}
              updateElement={({ id, title }) => updateCourse({ id, title })}
            />
            {eSignatureDisplayedAndRequired && signaturable && (
              <SignatureLabel>
                <ESignature>{t('e_signature')}</ESignature>
                <div>{t('required')}</div>
              </SignatureLabel>
            )}
            <RowActionsContainer>
              <ReadingTimeProvider>
                <ReadTimeFlyout
                  autoGeneratedReadingTime={autoGeneratedReadingTime}
                  courseId={courseId}
                  placement='auto'
                  readingTime={readingTime}
                />
              </ReadingTimeProvider>
              <StatusBadgeActions
                curriculumElement={{ id: curriculumElement.id, status }}
                curriculumId={curriculumId}
              />
              <ArrowIconWrapper onClick={() => routeTo(courseUrl)}>
                <Icon name='arrow-right' />
              </ArrowIconWrapper>
            </RowActionsContainer>
          </RowCardBody>
        </OutlineRowContainer>
        <CurriculumElementThreeDotWrapper remainVisible={isMenuOpen}>
          {!blockEditPrivileges && (
            <CurriculumElementThreeDot
              canBeModified={canBeModified}
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              eSignatureDisplayedAndRequired={eSignatureDisplayedAndRequired}
              isCurriculumLocked={isLocked}
              renameClickHandler={() => setIsEditing(true)}
              signaturable={signaturable}
            />
          )}
        </CurriculumElementThreeDotWrapper>
      </OutlineCurriculumElementRowContainer>
      <OutlineChildContainerRow style={containerStyle}>
        <div ref={ref}>
          {showSteps && (
            <Droppable
              droppableId={`step-${id}-course-${courseId}`}
              key={id}
              type={!eSignatureDisplayedAndRequired ? `droppableStep` : 'closed'}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  className='steps-group-handler'
                  ref={provided.innerRef}
                  style={{
                    position: 'relative',
                  }}
                >
                  <StepsList
                    blockEditPrivileges={blockEditPrivileges}
                    canBeModified={canBeModified}
                    courseId={courseId}
                    curriculumId={curriculumId}
                    disableTranslation={disableTranslation}
                    eSignatureDisplayedAndRequired={eSignatureDisplayedAndRequired}
                    id={id}
                    isLocked={isLocked}
                    isMobile={isMobile}
                    userAccess={userAccess}
                  />
                  {provided.placeholder}
                  {!isEmpty(placeholder) && snapshot.isDraggingOver && (
                    <PlaceholderBlock
                      style={{
                        top: placeholder.clientY,
                        left: placeholder.clientX,
                        height: placeholder.clientHeight,
                        width: placeholder.clientWidth,
                      }}
                    />
                  )}
                  {!isLocked && (
                    <>
                      {!blockEditPrivileges && (
                        <StyledActionRowWrapper>
                          <OutlineActionRow
                            disabled={!canBeModified}
                            errorText={t('enter_title')}
                            hasMaxValue
                            id={`course-${id}-action-row`}
                            maxError={t('max_length_error')}
                            name='new-step-row'
                            placeholder={t('outline.enter_step_title', {
                              step: stepTermSingular.toLowerCase(),
                            })}
                            primaryButtonAction={primaryButtonAction}
                            primaryButtonId={`inline-create-step-button-${id}`}
                            primaryButtonText={t('outline.create', {
                              step: stepTermSingular.toLowerCase(),
                            })}
                            tooltipText={t('disabled_by_e_signature')}
                            withBorder={false}
                          />
                        </StyledActionRowWrapper>
                      )}
                      {eSignatureDisplayedAndRequired && (
                        <ESignatureRowContainer>
                          <ESignatureRowContent>
                            <Icon name='signature' size='xs' weight='solid' />
                            <span>{t('e_signature_is_required')}</span>
                          </ESignatureRowContent>
                        </ESignatureRowContainer>
                      )}
                    </>
                  )}
                </div>
              )}
            </Droppable>
          )}
        </div>
      </OutlineChildContainerRow>
    </div>
  );
};

export default OutlineCourseTableRow;
