import React, { useState } from 'react';
import { Route } from 'type-route';

import { CurriculumEditProvider } from '../../../../contexts/CurriculumEditContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useFlashNoticeCookie from '../../../../hooks/useFlashNoticeCookie';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import PageContent from '../../shared/page_content';
import ESignaturePaywallBanner from '../ESignaturePaywallBanner/ESignaturePaywallBanner';
import DeleteCurriculumConfirmationModal from '../modals/DeleteCurriculumConfirmationModal';
import SuggestedTopicsSlideout from './BodyHeader/AIOutliner/SuggestedTopicsSlideout/SuggestedTopicsSlideout';
import BodyHeader from './BodyHeader/BodyHeader';
import CurriculumOutline from './CurriculumOutline/CurriculumOutline';

export type Props = {
  route:
    | Route<typeof routes.curriculumEdit>
    | Route<typeof routes.curriculumAdmin>
    | Route<typeof routes.curriculumV2Edit>;
};

const CurriculumEdit = ({ route }: Props) => {
  const {
    params: { id },
  } = route;
  const ability = useCurrentUserAbilities();
  const { slug } = useCurrentAccount();
  const [showDeleteCurriculumModal, setShowDeleteCurriculumModal] = useState(false);
  const canEditCurriculum = ability.can('update', `EditCurriculum-${id}`);
  useFlashNoticeCookie();

  if (!canEditCurriculum) {
    routeTo(routes.curriculumShow({ slug, id }).href);
    return <></>;
  }

  return (
    <CurriculumEditProvider
      setShowDeleteCurriculumModal={setShowDeleteCurriculumModal}
      showDeleteCurriculumModal={showDeleteCurriculumModal}
    >
      <PageContent id='curriculum-edit'>
        {showDeleteCurriculumModal && (
          <DeleteCurriculumConfirmationModal
            closeModal={() => setShowDeleteCurriculumModal(false)}
            id={id}
          />
        )}
        <BodyHeader id={id} />
        <CurriculumOutline curriculumId={id} />
        <SuggestedTopicsSlideout curriculumId={id} />
      </PageContent>
      <ESignaturePaywallBanner />
    </CurriculumEditProvider>
  );
};

export default CurriculumEdit;
