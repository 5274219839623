import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { useCurriculumElements } from '../../../../../../contexts/CurriculumElementsContext';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../../redux/errors/BasicErrorDisplay';
import {
  useDragCurriculumElementMutation,
  useGetCurriculumElementsForAdminQuery,
} from '../../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useDragStepMutation } from '../../../../../../redux/services/resourceApis/steps/stepsApi';
import { MetaUserAccess } from '../../../../../../types/Curriculum';
import CurriculumElementActionRow from '../../../../shared/CurriculumElements/CurriculumElementActionRow/CurriculumElementActionRow';
import { PlaceholderBlock } from '../../../../shared/DragAndDrop/styles';
import { Placeholder } from '../../../../shared/DragAndDrop/types';
import { handleDragUpdate, handleStartDrag } from '../../../../shared/DragAndDrop/utils';
import TableLoader from '../../../../shared/Loaders/TableLoader';
import CurriculumElementsList from '../CurriculumElementsList/CurriculumElementsList';

const t = initTranslations('curriculum_element_factory');

const CurriculumElementsWrapper = styled.div`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  position: relative;
`;

export interface CurriculumElementsTableProps {
  curriculumId: number;
  blockEditPrivileges?: boolean;
  userAccess?: MetaUserAccess;
  isLocked: boolean;
  signaturable: boolean;
}

const CurriculumElementsTable = ({
  curriculumId,
  userAccess,
  isLocked,
  signaturable,
  blockEditPrivileges = false,
}: CurriculumElementsTableProps) => {
  const { isLoading, error, data } = useGetCurriculumElementsForAdminQuery(curriculumId);
  const { curriculumElements } = data || {};
  const [dragCurriculumElement, result] = useDragCurriculumElementMutation();
  const [dragStep, stepResult] = useDragStepMutation();
  const { courseSteps } = useCurriculumElements();
  const [placeholder, setPlaceholder] = useState<Placeholder>({});

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('drag_failure'),
  });

  useDisplayFlashOnResponse({
    result: stepResult,
    errorMessage: t('drag_failure'),
  });

  const handleOnDragEnd = useCallback(
    async (result: DropResult) => {
      const { destination, draggableId, type } = result;

      if (!destination) return;

      const draggableNumberId = parseInt(draggableId.split('-')[1]);
      const destinationTag = destination.droppableId.split('-')[0];
      const destinationId = parseInt(destination.droppableId.split('-')[1]);
      const newCourseId = parseInt(destination.droppableId.split('-')[3]);

      if (destinationTag === 'curriculumElement') {
        dragCurriculumElement({
          id: draggableNumberId,
          position: destination.index + 1,
          newCurriculumId: destinationId,
        });
      } else if (type === 'droppableStep') {
        const oldCurriculumElementId = parseInt(draggableId.split('-')[3]);
        const oldCourseId = parseInt(draggableId.split('-')[5]) as number;
        const steps = courseSteps[oldCourseId];

        const draggedStep = steps?.find((step) => step.id === draggableNumberId);

        if (!draggedStep) return;

        dragStep({
          id: draggableNumberId,
          position: destination.index + 1,
          curriculumId,
          newCurriculumElementId: destinationId,
          newCourseId,
          oldCurriculumElementId,
          oldCourseId,
          draggedStep,
        });
      }
    },
    [courseSteps, curriculumId, dragCurriculumElement, dragStep]
  );

  if (isLoading) return <TableLoader />;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!curriculumElements) return <></>;

  return (
    <DragDropContext
      onDragEnd={handleOnDragEnd}
      onDragStart={(event) => handleStartDrag(event, setPlaceholder)}
      onDragUpdate={(event) => handleDragUpdate(event, setPlaceholder)}
    >
      <Droppable droppableId={`curriculumElement-${curriculumId}`}>
        {(provided, snapshot) => (
          <CurriculumElementsWrapper
            {...provided.droppableProps}
            className='curriculum-elements-table-factory'
            ref={provided.innerRef}
          >
            <CurriculumElementsList
              blockEditPrivileges={blockEditPrivileges}
              curriculumElements={curriculumElements}
              curriculumId={curriculumId}
              isLocked={isLocked}
              placeholder={placeholder}
              signaturable={signaturable}
              userAccess={userAccess}
            />
            {provided.placeholder}
            {!isEmpty(placeholder) && snapshot.isDraggingOver && (
              <PlaceholderBlock
                style={{
                  top: placeholder.clientY,
                  left: placeholder.clientX,
                  height: placeholder.clientHeight,
                  width: placeholder.clientWidth,
                }}
              />
            )}
            {!isLocked && !blockEditPrivileges && (
              <CurriculumElementActionRow
                autoFocus={!curriculumElements.length}
                curriculumId={curriculumId}
                signaturable={signaturable}
              />
            )}
          </CurriculumElementsWrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CurriculumElementsTable;
